import React from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import Cards from '../components/Cards';
import ModularBlocks from '../components/ModularBlocks';

const ThankYouPageTemplate = ({
  data: {
    datoCmsThankYou: { seoMetaTags, title, bannerImage, modularBlocks },
  },
}) => {
  return (
    <Layout noIndex={true} toggleButton={true}>
      <HelmetDatoCms seo={seoMetaTags} />
      <main>
        <Banner heading={title} image={bannerImage} />
        <ModularBlocks items={modularBlocks} />
      </main>
    </Layout>
  );
};

export const ThankYouPageTemplateQuery = graphql`
  query ThankYouPageTemplateQuery($id: String!) {
    datoCmsThankYou(id: { eq: $id }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      bannerImage {
        ...BannerImageFragment
      }
      modularBlocks {
        ...CaseStudiesModularBlockFragment
        ...ContentModularBlockFragment
        ...NewsInsightsModularBlockFragment
        ...TestimonialsModularBlockFragment
      }
    }
  }
`;

export default ThankYouPageTemplate;
